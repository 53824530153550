/**
 * Status visibility
 */

.is-hidden {
    display    : none !important;
    visibility : hidden !important;
    //opacity    : 0 !important;
    //position   : absolute !important;
    //top        : -20px !important;
    transition : all 0.3s !important;
}

.is-visible {
    display    : block !important;
    visibility : visible !important;
    opacity    : 1 !important;
    position   : relative !important;
    top        : 0 !important;
    transition : all 0.3s !important;
}

.is-rotate {
    transform : rotate(90deg) !important;
}


/**
 * Toggle image depending of viewport
 *
 * 1. Create a wrapper for 2 images
 * 2. Set 2 images in wrapper
 * 3. Add class 'is-desktop' and 'is-mobile' in the <img> HTML Tag. Example:
 *
 * <f:if condition="{image.0} || {mobile_image.0}">
 *    <f:media class="is-mobile" file="{mobile_image.0}" alt="{mobile_image.0.name}"/>
 *    <f:media class="is-desktop" file="{image.0}" alt="{image.0.name}"/>
 * </f:if>
 */

@media only screen and (max-width : 650px) {
    // If mobile viewport hide desktop
    .is-desktop {
        display : none;
    }
}

@media only screen and (min-width : 650px) {
    // If desktop viewport hide mobile
    .is-mobile {
        display : none;
    }
}
