//* ==========================================================================
//  #ICONS
//  ========================================================================== */


/// icon size
$icon-size : 24px !default;

.c-icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family             : 'iconfont' !important;
    speak                   : never;
    font-style              : normal;
    font-weight             : normal;
    font-variant            : normal;
    text-transform          : none;
    line-height             : 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing  : antialiased;
    -moz-osx-font-smoothing : grayscale;
}

.c-icon--Stillen:before {
    content : '\e91e';
}

.c-icon--arrow-down1:before {
    content : '\e91f';
}

.c-icon--arrow-left1:before {
    content : '\e920';
}

.c-icon--arrow-right1:before {
    content : '\e921';
}

.c-icon--arrow-up1:before {
    content : '\e922';
}

.c-icon--home:before {
    content : '\e923';
}

.c-icon--pencil:before {
    content : '\e924';
}

.c-icon--arrow-right-circle:before {
    content : '\e925';
}

.c-icon--deals:before {
    content : '\e926';
}

.c-icon--nuk-heart:before {
    content : '\e927';
}

.c-icon--hint:before {
    content : '\e928';
}

.c-icon--eyedropper:before {
    content : '\e931';
}

.c-icon--my-nuk:before {
    content : '\e932';
}

.c-icon--new:before {
    content : '\e929';
}

.c-icon--image:before {
    content : '\e92a';
}

.c-icon--pacifier:before {
    content : '\e92b';
}

.c-icon--camera:before {
    content : '\e92c';
}

.c-icon--music:before {
    content : '\e92d';
}

.c-icon--youtube1:before {
    content : '\e92e';
}

.c-icon--price-tag:before {
    content : '\e935';
}

.c-icon--cart1:before {
    content : '\e93a';
}

.c-icon--lifebuoy:before {
    content : '\e941';
}

.c-icon--phone1:before {
    content : '\e942';
}

.c-icon--envelop:before {
    content : '\e945';
}

.c-icon--location:before {
    content : '\e947';
}

.c-icon--floppy-disk:before {
    content : '\e962';
}

.c-icon--bubble:before {
    content : '\e96b';
}

.c-icon--bubbles:before {
    content : '\e970';
}

.c-icon--user1:before {
    content : '\e971';
}

.c-icon--users:before {
    content : '\e972';
}

.c-icon--spinner:before {
    content : '\e97a';
}

.c-icon--search1:before {
    content : '\e986';
}

.c-icon--zoom-in:before {
    content : '\e987';
}

.c-icon--zoom-out:before {
    content : '\e988';
}

.c-icon--enlarge:before {
    content : '\e989';
}

.c-icon--shrink:before {
    content : '\e98a';
}

.c-icon--key:before {
    content : '\e98d';
}

.c-icon--lock:before {
    content : '\e98f';
}

.c-icon--unlocked:before {
    content : '\e990';
}

.c-icon--wrench:before {
    content : '\e991';
}

.c-icon--equalizer:before {
    content : '\e993';
}

.c-icon--cog:before {
    content : '\e994';
}

.c-icon--cogs:before {
    content : '\e995';
}

.c-icon--compare:before {
    content : '\e99c';
}

.c-icon--bin:before {
    content : '\e9ad';
}

.c-icon--truck:before {
    content : '\e9b0';
}

.c-icon--menu1:before {
    content : '\e9bd';
}

.c-icon--cloud-download:before {
    content : '\e9c2';
}

.c-icon--link:before {
    content : '\e9cb';
}

.c-icon--attachment:before {
    content : '\e9cd';
}

.c-icon--star-empty:before {
    content : '\e9d7';
}

.c-icon--star-half:before {
    content : '\e9d8';
}

.c-icon--star-full:before {
    content : '\e9d9';
}

.c-icon--heart:before {
    content : '\e9da';
}

.c-icon--warning:before {
    content : '\ea07';
}

.c-icon--notification:before {
    content : '\ea08';
}

.c-icon--plus:before {
    content : '\ea0a';
}

.c-icon--minus:before {
    content : '\ea0b';
}

.c-icon--info:before {
    content : '\ea0c';
}

.c-icon--cancel-circle:before {
    content : '\ea0d';
}

.c-icon--blocked:before {
    content : '\ea0e';
}

.c-icon--cross:before {
    content : '\ea0f';
}

.c-icon--checkmark:before {
    content : '\ea10';
}

.c-icon--circle-play:before {
    content : '\ea15';
}

.c-icon--play:before {
    content : '\ea1c';
}

.c-icon--pause:before {
    content : '\ea1d';
}

.c-icon--stop:before {
    content : '\ea1e';
}

.c-icon--volume-high:before {
    content : '\ea26';
}

.c-icon--volume-medium:before {
    content : '\ea27';
}

.c-icon--volume-low:before {
    content : '\ea28';
}

.c-icon--volume-min:before {
    content : '\ea29';
}

.c-icon--volume-mute:before {
    content : '\ea2a';
}

.c-icon--loop:before {
    content : '\ea2e';
}

.c-icon--arrow-desc:before {
    content : '\ea3a';
}

.c-icon--arrow-asc:before {
    content : '\ea3e';
}

.c-icon--circle-up:before {
    content : '\ea41';
}

.c-icon--circle-right:before {
    content : '\ea42';
}

.c-icon--circle-down:before {
    content : '\ea43';
}

.c-icon--circle-left:before {
    content : '\ea44';
}

.c-icon--checkbox-checked:before {
    content : '\ea52';
}

.c-icon--checkbox-unchecked:before {
    content : '\ea53';
}

.c-icon--radio-checked:before {
    content : '\ea54';
}

.c-icon--radio-unchecked:before {
    content : '\ea56';
}

.c-icon--filter:before {
    content : '\ea5b';
}

.c-icon--share:before {
    content : '\ea82';
}

.c-icon--google-plus:before {
    content : '\ea8b';
}

.c-icon--facebook1:before {
    content : '\ea90';
}

.c-icon--instagram:before {
    content : '\ea92';
}

.c-icon--whatsapp:before {
    content : '\ea93';
}

.c-icon--twitter:before {
    content : '\ea96';
}

.c-icon--vimeo:before {
    content : '\eaa0';
}

.c-icon--paypal:before {
    content : '\ead8';
}

.c-icon--shop:before {
    content : '\ead9';
}

.c-icon--excklusive-light:before {
    content : '\e92f';
    color   : #656667;
}

.c-icon--excklusive:before {
    content : '\e930';
    color   : #656667;
}

.c-icon--brush:before {
    content : '\e900';
}

.c-icon--delivery:before {
    content : '\e915';
}

.c-icon--invoice:before {
    content : '\e916';
}

.c-icon--return:before {
    content : '\e917';
}

.c-icon--soothers:before {
    content : '\e918';
}

.c-icon--service:before {
    content : '\e919';
}

.c-icon--breastfeeding:before {
    content : '\e91a';
}

.c-icon--bottle:before {
    content : '\e91b';
}

.c-icon--learner-bottles:before {
    content : '\e91c';
}

.c-icon--menu:before {
    content : '\e901';
}

.c-icon--user:before {
    content : '\e902';
}

.c-icon--search:before {
    content : '\e903';
}

.c-icon--cart:before {
    content : '\e904';
}

.c-icon--arrow-1:before {
    content : '\e905';
}

.c-icon--arrow-2:before {
    content : '\e906';
}

.c-icon--arrow-down:before {
    content : '\e907';
}

.c-icon--arrow-left:before {
    content : '\e909';
}

.c-icon--arrow-right:before {
    content : '\e908';
}

.c-icon--arrow-up:before {
    content : '\e90a';
}

.c-icon--edit-cart:before {
    content : '\e90b';
}

.c-icon--settings:before {
    content : '\e90c';
}

.c-icon--facebook:before {
    content : '\e90d';
}

.c-icon--Instagram:before {
    content : '\e90e';
}

.c-icon--youtube:before {
    content : '\e90f';
}

.c-icon--pinterest:before {
    content : '\e910';
}

.c-icon--language:before {
    content : '\e911';
}

.c-icon--phone:before {
    content : '\e912';
}

.c-icon--question:before {
    content : '\e913';
}

.c-icon--chat:before {
    content : '\e914';
}
