//* ==========================================================================
//  #HEADINGS
//  ========================================================================== */

.home-rebrush {
    .u-h1 {
        font-size   : 40px !important;
        font-weight : normal !important;

        @media only screen and (min-width : 1024px) {
            font-size : 80px !important;
        }
    }

    .u-h2 {
        font-size   : 40px !important;
        font-weight : normal !important;

        @media only screen and (min-width : 1024px) {
            font-size : 70px !important;
        }
    }

    .u-h3 {
        font-size     : 32px !important;
        font-weight   : normal !important;
        margin-top    : 0;
        margin-bottom : 24px;

        @media only screen and (min-width : 1024px) {
            font-size : 50px !important;
        }
    }

    .u-h4 {
        font-family   : Arial, serif !important;
        font-size     : 24px !important;
        font-weight   : bold !important;
        margin-top    : 0;
        margin-bottom : 24px;
    }
}

.page-products {
    .u-h1 {
        font-size   : 40px !important;
        font-weight : normal !important;
        margin: 0;
        line-height: 1 !important;

        @media only screen and (min-width : 1024px) {
            font-size : 70px !important;
        }
    }

    .u-h2 {
        line-height: 1;
    }

    .u-h3 {
        font-weight: bold;
        font-size: 16px !important;
        @media only screen and (min-width : 1024px) {
            font-size : 20px !important;
        }
    }


    pre {
        background: none;
        border: none;
        color: #646363;
        font-family: 'Arial', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-size: 12px;
        white-space: break-spaces;
    }
}
