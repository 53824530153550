.page-perfect-match {

    .o-wrapper {
        padding: 0;
        margin: 0;
    }

    .c-video-teaser.dark-denim-blue {
        background-color: #a3bece;
    }

    .c-video-teaser__btn.c-btn.c-btn--inverted {
        background-image: unset;
        background-color: #ec1b21;
        color: #ffffff
    }

    h1.u-h1.font-professional {
        font-size: 40px !important;
    }

    @media screen and (min-width: 61.25em) {
        h1.u-h1.font-professional {
            font-size: 70px !important;
        }

    }
}
