
.u-1\/1 {
    width: 100% !important;
}
.u-push-1\/1 {
    position: relative !important;
    right: auto !important;
    left: 100% !important;
}
.u-pull-1\/1 {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
}
.u-1\/2 {
    width: 50% !important;
}
.u-push-1\/2 {
    position: relative !important;
    right: auto !important;
    left: 50% !important;
}
.u-pull-1\/2 {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
}
.u-2\/2 {
    width: 100% !important;
}
.u-push-2\/2 {
    position: relative !important;
    right: auto !important;
    left: 100% !important;
}
.u-pull-2\/2 {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
}
.u-1\/3 {
    width: 33.33333% !important;
}
.u-push-1\/3 {
    position: relative !important;
    right: auto !important;
    left: 33.33333% !important;
}
.u-pull-1\/3 {
    position: relative !important;
    right: 33.33333% !important;
    left: auto !important;
}
.u-2\/3 {
    width: 66.66667% !important;
}
.u-push-2\/3 {
    position: relative !important;
    right: auto !important;
    left: 66.66667% !important;
}
.u-pull-2\/3 {
    position: relative !important;
    right: 66.66667% !important;
    left: auto !important;
}
.u-3\/3 {
    width: 100% !important;
}
.u-push-3\/3 {
    position: relative !important;
    right: auto !important;
    left: 100% !important;
}
.u-pull-3\/3 {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
}
.u-1\/4 {
    width: 25% !important;
}
.u-push-1\/4 {
    position: relative !important;
    right: auto !important;
    left: 25% !important;
}
.u-pull-1\/4 {
    position: relative !important;
    right: 25% !important;
    left: auto !important;
}
.u-2\/4 {
    width: 50% !important;
}
.u-push-2\/4 {
    position: relative !important;
    right: auto !important;
    left: 50% !important;
}
.u-pull-2\/4 {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
}
.u-3\/4 {
    width: 75% !important;
}
.u-push-3\/4 {
    position: relative !important;
    right: auto !important;
    left: 75% !important;
}
.u-pull-3\/4 {
    position: relative !important;
    right: 75% !important;
    left: auto !important;
}
.u-4\/4 {
    width: 100% !important;
}
.u-push-4\/4 {
    position: relative !important;
    right: auto !important;
    left: 100% !important;
}
.u-pull-4\/4 {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
}
.u-1\/5 {
    width: 20% !important;
}
.u-push-1\/5 {
    position: relative !important;
    right: auto !important;
    left: 20% !important;
}
.u-pull-1\/5 {
    position: relative !important;
    right: 20% !important;
    left: auto !important;
}
.u-2\/5 {
    width: 40% !important;
}
.u-push-2\/5 {
    position: relative !important;
    right: auto !important;
    left: 40% !important;
}
.u-pull-2\/5 {
    position: relative !important;
    right: 40% !important;
    left: auto !important;
}
.u-3\/5 {
    width: 60% !important;
}
.u-push-3\/5 {
    position: relative !important;
    right: auto !important;
    left: 60% !important;
}
.u-pull-3\/5 {
    position: relative !important;
    right: 60% !important;
    left: auto !important;
}
.u-4\/5 {
    width: 80% !important;
}
.u-push-4\/5 {
    position: relative !important;
    right: auto !important;
    left: 80% !important;
}
.u-pull-4\/5 {
    position: relative !important;
    right: 80% !important;
    left: auto !important;
}
.u-5\/5 {
    width: 100% !important;
}
.u-push-5\/5 {
    position: relative !important;
    right: auto !important;
    left: 100% !important;
}
.u-pull-5\/5 {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
}
.u-1\/6 {
    width: 16.66667% !important;
}
.u-push-1\/6 {
    position: relative !important;
    right: auto !important;
    left: 16.66667% !important;
}
.u-pull-1\/6 {
    position: relative !important;
    right: 16.66667% !important;
    left: auto !important;
}
.u-2\/6 {
    width: 33.33333% !important;
}
.u-push-2\/6 {
    position: relative !important;
    right: auto !important;
    left: 33.33333% !important;
}
.u-pull-2\/6 {
    position: relative !important;
    right: 33.33333% !important;
    left: auto !important;
}
.u-3\/6 {
    width: 50% !important;
}
.u-push-3\/6 {
    position: relative !important;
    right: auto !important;
    left: 50% !important;
}
.u-pull-3\/6 {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
}
.u-4\/6 {
    width: 66.66667% !important;
}
.u-push-4\/6 {
    position: relative !important;
    right: auto !important;
    left: 66.66667% !important;
}
.u-pull-4\/6 {
    position: relative !important;
    right: 66.66667% !important;
    left: auto !important;
}
.u-5\/6 {
    width: 83.33333% !important;
}
.u-push-5\/6 {
    position: relative !important;
    right: auto !important;
    left: 83.33333% !important;
}
.u-pull-5\/6 {
    position: relative !important;
    right: 83.33333% !important;
    left: auto !important;
}
.u-6\/6 {
    width: 100% !important;
}
.u-push-6\/6 {
    position: relative !important;
    right: auto !important;
    left: 100% !important;
}
.u-pull-6\/6 {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
}
@media (min-width:20em) {
    .u-1\/1\-s {
        width: 100% !important;
    }
    .u-push-1\/1\-s {
        position: relative !important;
        right: auto !important;
        left: 100% !important;
    }
    .u-pull-1\/1\-s {
        position: relative !important;
        right: 100% !important;
        left: auto !important;
    }
    .u-1\/2\-s {
        width: 50% !important;
    }
    .u-1\/2\-m {
        width: 100% !important;
    }
    .u-3\/4\-m {
        width: 100% !important;
    }
    .u-push-1\/2\-s {
        position: relative !important;
        right: auto !important;
        left: 50% !important;
    }
    .u-pull-1\/2\-s {
        position: relative !important;
        right: 50% !important;
        left: auto !important;
    }
    .u-2\/2\-s {
        width: 100% !important;
    }
    .u-push-2\/2\-s {
        position: relative !important;
        right: auto !important;
        left: 100% !important;
    }
    .u-pull-2\/2\-s {
        position: relative !important;
        right: 100% !important;
        left: auto !important;
    }
    .u-1\/3\-s {
        width: 33.33333% !important;
    }
    .u-push-1\/3\-s {
        position: relative !important;
        right: auto !important;
        left: 33.33333% !important;
    }
    .u-pull-1\/3\-s {
        position: relative !important;
        right: 33.33333% !important;
        left: auto !important;
    }
    .u-2\/3\-s {
        width: 66.66667% !important;
    }
    .u-push-2\/3\-s {
        position: relative !important;
        right: auto !important;
        left: 66.66667% !important;
    }
    .u-pull-2\/3\-s {
        position: relative !important;
        right: 66.66667% !important;
        left: auto !important;
    }
    .u-3\/3\-s {
        width: 100% !important;
    }
    .u-push-3\/3\-s {
        position: relative !important;
        right: auto !important;
        left: 100% !important;
    }
    .u-pull-3\/3\-s {
        position: relative !important;
        right: 100% !important;
        left: auto !important;
    }
    .u-1\/4\-s {
        width: 25% !important;
    }
    .u-push-1\/4\-s {
        position: relative !important;
        right: auto !important;
        left: 25% !important;
    }
    .u-pull-1\/4\-s {
        position: relative !important;
        right: 25% !important;
        left: auto !important;
    }
    .u-2\/4\-s {
        width: 50% !important;
    }
    .u-push-2\/4\-s {
        position: relative !important;
        right: auto !important;
        left: 50% !important;
    }
    .u-pull-2\/4\-s {
        position: relative !important;
        right: 50% !important;
        left: auto !important;
    }
    .u-3\/4\-s {
        width: 75% !important;
    }
    .u-push-3\/4\-s {
        position: relative !important;
        right: auto !important;
        left: 75% !important;
    }
    .u-pull-3\/4\-s {
        position: relative !important;
        right: 75% !important;
        left: auto !important;
    }
    .u-4\/4\-s {
        width: 100% !important;
    }
    .u-push-4\/4\-s {
        position: relative !important;
        right: auto !important;
        left: 100% !important;
    }
    .u-pull-4\/4\-s {
        position: relative !important;
        right: 100% !important;
        left: auto !important;
    }
    .u-1\/5\-s {
        width: 20% !important;
    }
    .u-push-1\/5\-s {
        position: relative !important;
        right: auto !important;
        left: 20% !important;
    }
    .u-pull-1\/5\-s {
        position: relative !important;
        right: 20% !important;
        left: auto !important;
    }
    .u-2\/5\-s {
        width: 40% !important;
    }
    .u-push-2\/5\-s {
        position: relative !important;
        right: auto !important;
        left: 40% !important;
    }
    .u-pull-2\/5\-s {
        position: relative !important;
        right: 40% !important;
        left: auto !important;
    }
    .u-3\/5\-s {
        width: 60% !important;
    }
    .u-push-3\/5\-s {
        position: relative !important;
        right: auto !important;
        left: 60% !important;
    }
    .u-pull-3\/5\-s {
        position: relative !important;
        right: 60% !important;
        left: auto !important;
    }
    .u-4\/5\-s {
        width: 80% !important;
    }
    .u-push-4\/5\-s {
        position: relative !important;
        right: auto !important;
        left: 80% !important;
    }
    .u-pull-4\/5\-s {
        position: relative !important;
        right: 80% !important;
        left: auto !important;
    }
    .u-5\/5\-s {
        width: 100% !important;
    }
    .u-push-5\/5\-s {
        position: relative !important;
        right: auto !important;
        left: 100% !important;
    }
    .u-pull-5\/5\-s {
        position: relative !important;
        right: 100% !important;
        left: auto !important;
    }
    .u-1\/6\-s {
        width: 16.66667% !important;
    }
    .u-push-1\/6\-s {
        position: relative !important;
        right: auto !important;
        left: 16.66667% !important;
    }
    .u-pull-1\/6\-s {
        position: relative !important;
        right: 16.66667% !important;
        left: auto !important;
    }
    .u-2\/6\-s {
        width: 33.33333% !important;
    }
    .u-push-2\/6\-s {
        position: relative !important;
        right: auto !important;
        left: 33.33333% !important;
    }
    .u-pull-2\/6\-s {
        position: relative !important;
        right: 33.33333% !important;
        left: auto !important;
    }
    .u-3\/6\-s {
        width: 50% !important;
    }
    .u-push-3\/6\-s {
        position: relative !important;
        right: auto !important;
        left: 50% !important;
    }
    .u-pull-3\/6\-s {
        position: relative !important;
        right: 50% !important;
        left: auto !important;
    }
    .u-4\/6\-s {
        width: 66.66667% !important;
    }
    .u-push-4\/6\-s {
        position: relative !important;
        right: auto !important;
        left: 66.66667% !important;
    }
    .u-pull-4\/6\-s {
        position: relative !important;
        right: 66.66667% !important;
        left: auto !important;
    }
    .u-5\/6\-s {
        width: 83.33333% !important;
    }
    .u-push-5\/6\-s {
        position: relative !important;
        right: auto !important;
        left: 83.33333% !important;
    }
    .u-pull-5\/6\-s {
        position: relative !important;
        right: 83.33333% !important;
        left: auto !important;
    }
    .u-6\/6\-s {
        width: 100% !important;
    }
    .u-push-6\/6\-s {
        position: relative !important;
        right: auto !important;
        left: 100% !important;
    }
    .u-pull-6\/6\-s {
        position: relative !important;
        right: 100% !important;
        left: auto !important;
    }
}
@media (min-width:48em) {
    .u-1\/1\-m {
        width: 100% !important;
    }
    .u-push-1\/1\-m {
        position: relative !important;
        right: auto !important;
        left: 100% !important;
    }
    .u-pull-1\/1\-m {
        position: relative !important;
        right: 100% !important;
        left: auto !important;
    }
    .u-1\/2\-m {
        width: 50% !important;
    }
    .u-push-1\/2\-m {
        position: relative !important;
        right: auto !important;
        left: 50% !important;
    }
    .u-pull-1\/2\-m {
        position: relative !important;
        right: 50% !important;
        left: auto !important;
    }
    .u-2\/2\-m {
        width: 100% !important;
    }
    .u-push-2\/2\-m {
        position: relative !important;
        right: auto !important;
        left: 100% !important;
    }
    .u-pull-2\/2\-m {
        position: relative !important;
        right: 100% !important;
        left: auto !important;
    }
    .u-1\/3\-m {
        width: 33.33333% !important;
    }
    .u-push-1\/3\-m {
        position: relative !important;
        right: auto !important;
        left: 33.33333% !important;
    }
    .u-pull-1\/3\-m {
        position: relative !important;
        right: 33.33333% !important;
        left: auto !important;
    }
    .u-2\/3\-m {
        width: 66.66667% !important;
    }
    .u-push-2\/3\-m {
        position: relative !important;
        right: auto !important;
        left: 66.66667% !important;
    }
    .u-pull-2\/3\-m {
        position: relative !important;
        right: 66.66667% !important;
        left: auto !important;
    }
    .u-3\/3\-m {
        width: 100% !important;
    }
    .u-push-3\/3\-m {
        position: relative !important;
        right: auto !important;
        left: 100% !important;
    }
    .u-pull-3\/3\-m {
        position: relative !important;
        right: 100% !important;
        left: auto !important;
    }
    .u-1\/4\-m {
        width: 25% !important;
    }
    .u-push-1\/4\-m {
        position: relative !important;
        right: auto !important;
        left: 25% !important;
    }
    .u-pull-1\/4\-m {
        position: relative !important;
        right: 25% !important;
        left: auto !important;
    }
    .u-2\/4\-m {
        width: 50% !important;
    }
    .u-push-2\/4\-m {
        position: relative !important;
        right: auto !important;
        left: 50% !important;
    }
    .u-pull-2\/4\-m {
        position: relative !important;
        right: 50% !important;
        left: auto !important;
    }
    .u-3\/4\-m {
        width: 75% !important;
    }
    .u-push-3\/4\-m {
        position: relative !important;
        right: auto !important;
        left: 75% !important;
    }
    .u-pull-3\/4\-m {
        position: relative !important;
        right: 75% !important;
        left: auto !important;
    }
    .u-4\/4\-m {
        width: 100% !important;
    }
    .u-push-4\/4\-m {
        position: relative !important;
        right: auto !important;
        left: 100% !important;
    }
    .u-pull-4\/4\-m {
        position: relative !important;
        right: 100% !important;
        left: auto !important;
    }
    .u-1\/5\-m {
        width: 20% !important;
    }
    .u-push-1\/5\-m {
        position: relative !important;
        right: auto !important;
        left: 20% !important;
    }
    .u-pull-1\/5\-m {
        position: relative !important;
        right: 20% !important;
        left: auto !important;
    }
    .u-2\/5\-m {
        width: 40% !important;
    }
    .u-push-2\/5\-m {
        position: relative !important;
        right: auto !important;
        left: 40% !important;
    }
    .u-pull-2\/5\-m {
        position: relative !important;
        right: 40% !important;
        left: auto !important;
    }
    .u-3\/5\-m {
        width: 60% !important;
    }
    .u-push-3\/5\-m {
        position: relative !important;
        right: auto !important;
        left: 60% !important;
    }
    .u-pull-3\/5\-m {
        position: relative !important;
        right: 60% !important;
        left: auto !important;
    }
    .u-4\/5\-m {
        width: 80% !important;
    }
    .u-push-4\/5\-m {
        position: relative !important;
        right: auto !important;
        left: 80% !important;
    }
    .u-pull-4\/5\-m {
        position: relative !important;
        right: 80% !important;
        left: auto !important;
    }
    .u-5\/5\-m {
        width: 100% !important;
    }
    .u-push-5\/5\-m {
        position: relative !important;
        right: auto !important;
        left: 100% !important;
    }
    .u-pull-5\/5\-m {
        position: relative !important;
        right: 100% !important;
        left: auto !important;
    }
    .u-1\/6\-m {
        width: 16.66667% !important;
    }
    .u-push-1\/6\-m {
        position: relative !important;
        right: auto !important;
        left: 16.66667% !important;
    }
    .u-pull-1\/6\-m {
        position: relative !important;
        right: 16.66667% !important;
        left: auto !important;
    }
    .u-2\/6\-m {
        width: 33.33333% !important;
    }
    .u-push-2\/6\-m {
        position: relative !important;
        right: auto !important;
        left: 33.33333% !important;
    }
    .u-pull-2\/6\-m {
        position: relative !important;
        right: 33.33333% !important;
        left: auto !important;
    }
    .u-3\/6\-m {
        width: 50% !important;
    }
    .u-push-3\/6\-m {
        position: relative !important;
        right: auto !important;
        left: 50% !important;
    }
    .u-pull-3\/6\-m {
        position: relative !important;
        right: 50% !important;
        left: auto !important;
    }
    .u-4\/6\-m {
        width: 66.66667% !important;
    }
    .u-push-4\/6\-m {
        position: relative !important;
        right: auto !important;
        left: 66.66667% !important;
    }
    .u-pull-4\/6\-m {
        position: relative !important;
        right: 66.66667% !important;
        left: auto !important;
    }
    .u-5\/6\-m {
        width: 83.33333% !important;
    }
    .u-push-5\/6\-m {
        position: relative !important;
        right: auto !important;
        left: 83.33333% !important;
    }
    .u-pull-5\/6\-m {
        position: relative !important;
        right: 83.33333% !important;
        left: auto !important;
    }
    .u-6\/6\-m {
        width: 100% !important;
    }
    .u-push-6\/6\-m {
        position: relative !important;
        right: auto !important;
        left: 100% !important;
    }
    .u-pull-6\/6\-m {
        position: relative !important;
        right: 100% !important;
        left: auto !important;
    }
}
@media (min-width:61.25em) {
    .u-1\/1\-l {
        width: 100% !important;
    }
    .u-push-1\/1\-l {
        position: relative !important;
        right: auto !important;
        left: 100% !important;
    }
    .u-pull-1\/1\-l {
        position: relative !important;
        right: 100% !important;
        left: auto !important;
    }
    .u-1\/2\-l {
        width: 50% !important;
    }
    .u-push-1\/2\-l {
        position: relative !important;
        right: auto !important;
        left: 50% !important;
    }
    .u-pull-1\/2\-l {
        position: relative !important;
        right: 50% !important;
        left: auto !important;
    }
    .u-2\/2\-l {
        width: 100% !important;
    }
    .u-push-2\/2\-l {
        position: relative !important;
        right: auto !important;
        left: 100% !important;
    }
    .u-pull-2\/2\-l {
        position: relative !important;
        right: 100% !important;
        left: auto !important;
    }
    .u-1\/3\-l {
        width: 33.33333% !important;
    }
    .u-push-1\/3\-l {
        position: relative !important;
        right: auto !important;
        left: 33.33333% !important;
    }
    .u-pull-1\/3\-l {
        position: relative !important;
        right: 33.33333% !important;
        left: auto !important;
    }
    .u-2\/3\-l {
        width: 66.66667% !important;
    }
    .u-push-2\/3\-l {
        position: relative !important;
        right: auto !important;
        left: 66.66667% !important;
    }
    .u-pull-2\/3\-l {
        position: relative !important;
        right: 66.66667% !important;
        left: auto !important;
    }
    .u-3\/3\-l {
        width: 100% !important;
    }
    .u-push-3\/3\-l {
        position: relative !important;
        right: auto !important;
        left: 100% !important;
    }
    .u-pull-3\/3\-l {
        position: relative !important;
        right: 100% !important;
        left: auto !important;
    }
    .u-1\/4\-l {
        width: 25% !important;
    }
    .u-push-1\/4\-l {
        position: relative !important;
        right: auto !important;
        left: 25% !important;
    }
    .u-pull-1\/4\-l {
        position: relative !important;
        right: 25% !important;
        left: auto !important;
    }
    .u-2\/4\-l {
        width: 50% !important;
    }
    .u-push-2\/4\-l {
        position: relative !important;
        right: auto !important;
        left: 50% !important;
    }
    .u-pull-2\/4\-l {
        position: relative !important;
        right: 50% !important;
        left: auto !important;
    }
    .u-3\/4\-l {
        width: 75% !important;
    }
    .u-push-3\/4\-l {
        position: relative !important;
        right: auto !important;
        left: 75% !important;
    }
    .u-pull-3\/4\-l {
        position: relative !important;
        right: 75% !important;
        left: auto !important;
    }
    .u-4\/4\-l {
        width: 100% !important;
    }
    .u-push-4\/4\-l {
        position: relative !important;
        right: auto !important;
        left: 100% !important;
    }
    .u-pull-4\/4\-l {
        position: relative !important;
        right: 100% !important;
        left: auto !important;
    }
    .u-1\/5\-l {
        width: 20% !important;
    }
    .u-push-1\/5\-l {
        position: relative !important;
        right: auto !important;
        left: 20% !important;
    }
    .u-pull-1\/5\-l {
        position: relative !important;
        right: 20% !important;
        left: auto !important;
    }
    .u-2\/5\-l {
        width: 40% !important;
    }
    .u-push-2\/5\-l {
        position: relative !important;
        right: auto !important;
        left: 40% !important;
    }
    .u-pull-2\/5\-l {
        position: relative !important;
        right: 40% !important;
        left: auto !important;
    }
    .u-3\/5\-l {
        width: 60% !important;
    }
    .u-push-3\/5\-l {
        position: relative !important;
        right: auto !important;
        left: 60% !important;
    }
    .u-pull-3\/5\-l {
        position: relative !important;
        right: 60% !important;
        left: auto !important;
    }
    .u-4\/5\-l {
        width: 80% !important;
    }
    .u-push-4\/5\-l {
        position: relative !important;
        right: auto !important;
        left: 80% !important;
    }
    .u-pull-4\/5\-l {
        position: relative !important;
        right: 80% !important;
        left: auto !important;
    }
    .u-5\/5\-l {
        width: 100% !important;
    }
    .u-push-5\/5\-l {
        position: relative !important;
        right: auto !important;
        left: 100% !important;
    }
    .u-pull-5\/5\-l {
        position: relative !important;
        right: 100% !important;
        left: auto !important;
    }
    .u-1\/6\-l {
        width: 16.66667% !important;
    }
    .u-push-1\/6\-l {
        position: relative !important;
        right: auto !important;
        left: 16.66667% !important;
    }
    .u-pull-1\/6\-l {
        position: relative !important;
        right: 16.66667% !important;
        left: auto !important;
    }
    .u-2\/6\-l {
        width: 33.33333% !important;
    }
    .u-push-2\/6\-l {
        position: relative !important;
        right: auto !important;
        left: 33.33333% !important;
    }
    .u-pull-2\/6\-l {
        position: relative !important;
        right: 33.33333% !important;
        left: auto !important;
    }
    .u-3\/6\-l {
        width: 50% !important;
    }
    .u-push-3\/6\-l {
        position: relative !important;
        right: auto !important;
        left: 50% !important;
    }
    .u-pull-3\/6\-l {
        position: relative !important;
        right: 50% !important;
        left: auto !important;
    }
    .u-4\/6\-l {
        width: 66.66667% !important;
    }
    .u-push-4\/6\-l {
        position: relative !important;
        right: auto !important;
        left: 66.66667% !important;
    }
    .u-pull-4\/6\-l {
        position: relative !important;
        right: 66.66667% !important;
        left: auto !important;
    }
    .u-5\/6\-l {
        width: 83.33333% !important;
    }
    .u-push-5\/6\-l {
        position: relative !important;
        right: auto !important;
        left: 83.33333% !important;
    }
    .u-pull-5\/6\-l {
        position: relative !important;
        right: 83.33333% !important;
        left: auto !important;
    }
    .u-6\/6\-l {
        width: 100% !important;
    }
    .u-push-6\/6\-l {
        position: relative !important;
        right: auto !important;
        left: 100% !important;
    }
    .u-pull-6\/6\-l {
        position: relative !important;
        right: 100% !important;
        left: auto !important;
    }
}
