// General button settings
$btn-color                    : color(base) !default;
$btn-font-family              : $font-base !default;
$btn-font-size--mobile        : font-size(xs) !default;
$btn-font-size--desktop       : font-size(m) !default;
$btn-height--mobile           : size(xl) !default;
$btn-height--desktop          : size(xxl) !default;
$btn-padding                  : 0 spacing(s) !default;
$btn-border-radius            : border-radius(m) !default;
// Button primary
$btn-primary-color            : color(white) !default;
$btn-primary-bg-color         : color(red) !default;
$btn-primary-bg-color-hover   : darken(color(red), 5%) !default;
// Button secondary
$btn-secondary-bg-color       : color(white) !default;
$btn-secondary-border-width   : border-width(m) !default;
$btn-secondary-border-color   : color(base) !default;
$btn-secondary-bg-color-hover : lighten(color(base), 50%) !default;
$btn-secondary-color-hover    : color(base) !default;
// Button inverted
$btn-inverted-bg-color        : color(white) !default;
$btn-inverted-border-width    : border-width(m) !default;
$btn-inverted-border-color    : color(base) !default;
// Button Download
$btn-download-bg-color        : #335D37 !default;
$btn-download-border-radius   : 20px !default;
$btn-download-color           : #ffffff !default;

.c-btn {
    color         : $btn-color;
    font-family   : $btn-font-family;
    width         : 100%;
    height        : $btn-height--mobile;
    font-size     : $btn-font-size--mobile;
    padding       : $btn-padding;
    border-radius : $btn-border-radius;
    font-weight   : bold;
    text-overflow : ellipsis;
    overflow      : hidden;
    white-space   : nowrap;
    border        : none;
    cursor        : pointer;
    box-shadow    : none;

    @include mq($from : desktop) {
        width     : fit-content;
        height    : $btn-height--desktop;
        font-size : $btn-font-size--desktop;
    }

    &.c-btn--primary {
        background-color : $btn-primary-bg-color;
        color            : $btn-primary-color;

        &:hover {
            background-color : $btn-primary-bg-color-hover;
            border: none!important;
        }

    }

    &.c-btn--secondary {
        outline          : $btn-secondary-border-width solid $btn-secondary-border-color !important;
        background-color : $btn-secondary-bg-color;

        &:hover {
            background-color : $btn-secondary-bg-color-hover;
            color            : $btn-secondary-color-hover;
            border           : none;
        }
    }

    &.c-btn--inverted {
        background-color : $btn-inverted-bg-color;

        &:hover {
            outline : $btn-inverted-border-width solid $btn-inverted-border-color !important;
        }
    }
}

.c-btn--download {
    border-radius: $btn-download-border-radius;
    background: $btn-download-bg-color;
    color: $btn-download-color;
}
