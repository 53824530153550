//* ==========================================================================
//  #COLORS
//  ========================================================================== */


// Colors map.
// Insert colors here, you wish to have a background-color
// and text-color utility-class for.
$colors: (
        base:           $color-base,
);

/**
 * Background colors.
 * Font colors.
 */
@each $color in $colors {

  $color-name:  nth($color, 1);
  $color-value: nth($color, 2);

  .u-bg-#{$color-name} {
    background-color: #{$color-value} !important;
  }

  .u-color-#{$color-name} {
    color: #{$color-value} !important;
  }
}

.u-bg-color-purple {
    background-color : color(purple);
}

.u-bg-color-green {
    background-color : color(green);
}

.u-bg-color-blue {
    background-color : color(blue);
}
