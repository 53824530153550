
.u-p {
    padding: 24px;
}
.u-p-tiny {
    padding: 6px;
}
.u-p-small {
    padding: 12px;
}
.u-p-large {
    padding: 48px;
}
.u-p-huge {
    padding: 96px;
}
.u-p-none {
    padding: 0;
}
.u-pt {
    padding-top: 24px;
}
.u-pt-tiny {
    padding-top: 6px;
}
.u-pt-small {
    padding-top: 12px;
}
.u-pt-large {
    padding-top: 48px;
}
.u-pt-huge {
    padding-top: 96px;
}
.u-pt-none {
    padding-top: 0;
}
.u-pr {
    padding-right: 24px;
}
.u-pr-tiny {
    padding-right: 6px;
}
.u-pr-small {
    padding-right: 12px;
}
.u-pr-large {
    padding-right: 48px;
}
.u-pr-huge {
    padding-right: 96px;
}
.u-pr-none {
    padding-right: 0;
}
.u-pb {
    padding-bottom: 24px;
}
.u-pb-tiny {
    padding-bottom: 6px;
}
.u-pb-small {
    padding-bottom: 12px;
}
.u-pb-large {
    padding-bottom: 48px;
}
.u-pb-huge {
    padding-bottom: 96px;
}
.u-pb-none {
    padding-bottom: 0;
}
.u-pl {
    padding-left: 24px;
}
.u-pl-tiny {
    padding-left: 6px;
}
.u-pl-small {
    padding-left: 12px;
}
.u-pl-large {
    padding-left: 48px;
}
.u-pl-huge {
    padding-left: 96px;
}
.u-pl-none {
    padding-left: 0;
}
.u-ph {
    padding-left: 24px;
    padding-right: 24px;
}
.u-ph-tiny {
    padding-left: 6px;
    padding-right: 6px;
}
.u-ph-small {
    padding-left: 12px;
    padding-right: 12px;
}
.u-ph-large {
    padding-left: 48px;
    padding-right: 48px;
}
.u-ph-huge {
    padding-left: 96px;
    padding-right: 96px;
}
.u-ph-none {
    padding-left: 0;
    padding-right: 0;
}
.u-pv {
    padding-top: 24px;
    padding-bottom: 24px;
}
.u-pv-tiny {
    padding-top: 6px;
    padding-bottom: 6px;
}
.u-pv-small {
    padding-top: 12px;
    padding-bottom: 12px;
}
.u-pv-large {
    padding-top: 48px;
    padding-bottom: 48px;
}
.u-pv-huge {
    padding-top: 96px;
    padding-bottom: 96px;
}
.u-pv-none {
    padding-top: 0;
    padding-bottom: 0;
}
.u-m {
    margin: 24px;
}
.u-m-tiny {
    margin: 6px;
}
.u-m-small {
    margin: 12px;
}
.u-m-large {
    margin: 48px;
}
.u-m-huge {
    margin: 96px;
}
.u-m-none {
    margin: 0;
}
.u-mt {
    margin-top: 24px;
}
.u-mt-tiny {
    margin-top: 6px;
}
.u-mt-small {
    margin-top: 12px;
}
.u-mt-large {
    margin-top: 48px;
}
.u-mt-huge {
    margin-top: 96px;
}
.u-mt-none {
    margin-top: 0;
}
.u-mr {
    margin-right: 24px;
}
.u-mr-tiny {
    margin-right: 6px;
}
.u-mr-small {
    margin-right: 12px;
}
.u-mr-large {
    margin-right: 48px;
}
.u-mr-huge {
    margin-right: 96px;
}
.u-mr-none {
    margin-right: 0;
}
.u-mb {
    margin-bottom: 24px;
}
.u-mb-tiny {
    margin-bottom: 6px;
}
.u-mb-small {
    margin-bottom: 12px;
}
.u-mb-large {
    margin-bottom: 48px;
}
.u-mb-huge {
    margin-bottom: 96px;
}
.u-mb-none {
    margin-bottom: 0;
}
.u-ml {
    margin-left: 24px;
}
.u-ml-tiny {
    margin-left: 6px;
}
.u-ml-small {
    margin-left: 12px;
}
.u-ml-large {
    margin-left: 48px;
}
.u-ml-huge {
    margin-left: 96px;
}
.u-ml-none {
    margin-left: 0;
}
.u-mh {
    margin-left: 24px;
    margin-right: 24px;
}
.u-mh-tiny {
    margin-left: 6px;
    margin-right: 6px;
}
.u-mh-small {
    margin-left: 12px;
    margin-right: 12px;
}
.u-mh-large {
    margin-left: 48px;
    margin-right: 48px;
}
.u-mh-huge {
    margin-left: 96px;
    margin-right: 96px;
}
.u-mh-none {
    margin-left: 0;
    margin-right: 0;
}
.u-mv {
    margin-top: 24px;
    margin-bottom: 24px;
}
.u-mv-tiny {
    margin-top: 6px;
    margin-bottom: 6px;
}
.u-mv-small {
    margin-top: 12px;
    margin-bottom: 12px;
}
.u-mv-large {
    margin-top: 48px;
    margin-bottom: 48px;
}
.u-mv-huge {
    margin-top: 96px;
    margin-bottom: 96px;
}
.u-mv-none {
    margin-top: 0;
    margin-bottom: 0;
}
@media (min-width:20em) {
    .u-pt\-s {
        padding-top: 24px;
    }
}@media (min-width:48em) {
    .u-pt\-m {
        padding-top: 24px;
    }
}@media (min-width:61.25em) {
    .u-pt\-l {
        padding-top: 24px;
    }
}@media (min-width:20em) {
    .u-pt-tiny\-s {
        padding-top: 6px;
    }
}@media (min-width:48em) {
    .u-pt-tiny\-m {
        padding-top: 6px;
    }
}@media (min-width:61.25em) {
    .u-pt-tiny\-l {
        padding-top: 6px;
    }
}@media (min-width:20em) {
    .u-pt-small\-s {
        padding-top: 12px;
    }
}@media (min-width:48em) {
    .u-pt-small\-m {
        padding-top: 12px;
    }
}@media (min-width:61.25em) {
    .u-pt-small\-l {
        padding-top: 12px;
    }
}@media (min-width:20em) {
    .u-pt-large\-s {
        padding-top: 48px;
    }
}@media (min-width:48em) {
    .u-pt-large\-m {
        padding-top: 48px;
    }
}@media (min-width:61.25em) {
    .u-pt-large\-l {
        padding-top: 48px;
    }
}@media (min-width:20em) {
    .u-pt-huge\-s {
        padding-top: 96px;
    }
}@media (min-width:48em) {
    .u-pt-huge\-m {
        padding-top: 96px;
    }
}@media (min-width:61.25em) {
    .u-pt-huge\-l {
        padding-top: 96px;
    }
}@media (min-width:20em) {
    .u-pt-none\-s {
        padding-top: 0;
    }
}@media (min-width:48em) {
    .u-pt-none\-m {
        padding-top: 0;
    }
}@media (min-width:61.25em) {
    .u-pt-none\-l {
        padding-top: 0;
    }
}@media (min-width:20em) {
    .u-pr\-s {
        padding-right: 24px;
    }
}@media (min-width:48em) {
    .u-pr\-m {
        padding-right: 24px;
    }
}@media (min-width:61.25em) {
    .u-pr\-l {
        padding-right: 24px;
    }
}@media (min-width:20em) {
    .u-pr-tiny\-s {
        padding-right: 6px;
    }
}@media (min-width:48em) {
    .u-pr-tiny\-m {
        padding-right: 6px;
    }
}@media (min-width:61.25em) {
    .u-pr-tiny\-l {
        padding-right: 6px;
    }
}@media (min-width:20em) {
    .u-pr-small\-s {
        padding-right: 12px;
    }
}@media (min-width:48em) {
    .u-pr-small\-m {
        padding-right: 12px;
    }
}@media (min-width:61.25em) {
    .u-pr-small\-l {
        padding-right: 12px;
    }
}@media (min-width:20em) {
    .u-pr-large\-s {
        padding-right: 48px;
    }
}@media (min-width:48em) {
    .u-pr-large\-m {
        padding-right: 48px;
    }
}@media (min-width:61.25em) {
    .u-pr-large\-l {
        padding-right: 48px;
    }
}@media (min-width:20em) {
    .u-pr-huge\-s {
        padding-right: 96px;
    }
}@media (min-width:48em) {
    .u-pr-huge\-m {
        padding-right: 96px;
    }
}@media (min-width:61.25em) {
    .u-pr-huge\-l {
        padding-right: 96px;
    }
}@media (min-width:20em) {
    .u-pr-none\-s {
        padding-right: 0;
    }
}@media (min-width:48em) {
    .u-pr-none\-m {
        padding-right: 0;
    }
}@media (min-width:61.25em) {
    .u-pr-none\-l {
        padding-right: 0;
    }
}@media (min-width:20em) {
    .u-pb\-s {
        padding-bottom: 24px;
    }
}@media (min-width:48em) {
    .u-pb\-m {
        padding-bottom: 24px;
    }
}@media (min-width:61.25em) {
    .u-pb\-l {
        padding-bottom: 24px;
    }
}@media (min-width:20em) {
    .u-pb-tiny\-s {
        padding-bottom: 6px;
    }
}@media (min-width:48em) {
    .u-pb-tiny\-m {
        padding-bottom: 6px;
    }
}@media (min-width:61.25em) {
    .u-pb-tiny\-l {
        padding-bottom: 6px;
    }
}@media (min-width:20em) {
    .u-pb-small\-s {
        padding-bottom: 12px;
    }
}@media (min-width:48em) {
    .u-pb-small\-m {
        padding-bottom: 12px;
    }
}@media (min-width:61.25em) {
    .u-pb-small\-l {
        padding-bottom: 12px;
    }
}@media (min-width:20em) {
    .u-pb-large\-s {
        padding-bottom: 48px;
    }
}@media (min-width:48em) {
    .u-pb-large\-m {
        padding-bottom: 48px;
    }
}@media (min-width:61.25em) {
    .u-pb-large\-l {
        padding-bottom: 48px;
    }
}@media (min-width:20em) {
    .u-pb-huge\-s {
        padding-bottom: 96px;
    }
}@media (min-width:48em) {
    .u-pb-huge\-m {
        padding-bottom: 96px;
    }
}@media (min-width:61.25em) {
    .u-pb-huge\-l {
        padding-bottom: 96px;
    }
}@media (min-width:20em) {
    .u-pb-none\-s {
        padding-bottom: 0;
    }
}@media (min-width:48em) {
    .u-pb-none\-m {
        padding-bottom: 0;
    }
}@media (min-width:61.25em) {
    .u-pb-none\-l {
        padding-bottom: 0;
    }
}@media (min-width:20em) {
    .u-pl\-s {
        padding-left: 24px;
    }
}@media (min-width:48em) {
    .u-pl\-m {
        padding-left: 24px;
    }
}@media (min-width:61.25em) {
    .u-pl\-l {
        padding-left: 24px;
    }
}@media (min-width:20em) {
    .u-pl-tiny\-s {
        padding-left: 6px;
    }
}@media (min-width:48em) {
    .u-pl-tiny\-m {
        padding-left: 6px;
    }
}@media (min-width:61.25em) {
    .u-pl-tiny\-l {
        padding-left: 6px;
    }
}@media (min-width:20em) {
    .u-pl-small\-s {
        padding-left: 12px;
    }
}@media (min-width:48em) {
    .u-pl-small\-m {
        padding-left: 12px;
    }
}@media (min-width:61.25em) {
    .u-pl-small\-l {
        padding-left: 12px;
    }
}@media (min-width:20em) {
    .u-pl-large\-s {
        padding-left: 48px;
    }
}@media (min-width:48em) {
    .u-pl-large\-m {
        padding-left: 48px;
    }
}@media (min-width:61.25em) {
    .u-pl-large\-l {
        padding-left: 48px;
    }
}@media (min-width:20em) {
    .u-pl-huge\-s {
        padding-left: 96px;
    }
}@media (min-width:48em) {
    .u-pl-huge\-m {
        padding-left: 96px;
    }
}@media (min-width:61.25em) {
    .u-pl-huge\-l {
        padding-left: 96px;
    }
}@media (min-width:20em) {
    .u-pl-none\-s {
        padding-left: 0;
    }
}@media (min-width:48em) {
    .u-pl-none\-m {
        padding-left: 0;
    }
}@media (min-width:61.25em) {
    .u-pl-none\-l {
        padding-left: 0;
    }
}@media (min-width:20em) {
    .u-ph\-s {
        padding-left: 24px;
        padding-right: 24px;
    }
}@media (min-width:48em) {
    .u-ph\-m {
        padding-left: 24px;
        padding-right: 24px;
    }
}@media (min-width:61.25em) {
    .u-ph\-l {
        padding-left: 24px;
        padding-right: 24px;
    }
}@media (min-width:20em) {
    .u-ph-tiny\-s {
        padding-left: 6px;
        padding-right: 6px;
    }
}@media (min-width:48em) {
    .u-ph-tiny\-m {
        padding-left: 6px;
        padding-right: 6px;
    }
}@media (min-width:61.25em) {
    .u-ph-tiny\-l {
        padding-left: 6px;
        padding-right: 6px;
    }
}@media (min-width:20em) {
    .u-ph-small\-s {
        padding-left: 12px;
        padding-right: 12px;
    }
}@media (min-width:48em) {
    .u-ph-small\-m {
        padding-left: 12px;
        padding-right: 12px;
    }
}@media (min-width:61.25em) {
    .u-ph-small\-l {
        padding-left: 12px;
        padding-right: 12px;
    }
}@media (min-width:20em) {
    .u-ph-large\-s {
        padding-left: 48px;
        padding-right: 48px;
    }
}@media (min-width:48em) {
    .u-ph-large\-m {
        padding-left: 48px;
        padding-right: 48px;
    }
}@media (min-width:61.25em) {
    .u-ph-large\-l {
        padding-left: 48px;
        padding-right: 48px;
    }
}@media (min-width:20em) {
    .u-ph-huge\-s {
        padding-left: 96px;
        padding-right: 96px;
    }
}@media (min-width:48em) {
    .u-ph-huge\-m {
        padding-left: 96px;
        padding-right: 96px;
    }
}@media (min-width:61.25em) {
    .u-ph-huge\-l {
        padding-left: 96px;
        padding-right: 96px;
    }
}@media (min-width:20em) {
    .u-ph-none\-s {
        padding-left: 0;
        padding-right: 0;
    }
}@media (min-width:48em) {
    .u-ph-none\-m {
        padding-left: 0;
        padding-right: 0;
    }
}@media (min-width:61.25em) {
    .u-ph-none\-l {
        padding-left: 0;
        padding-right: 0;
    }
}@media (min-width:20em) {
    .u-pv\-s {
        padding-top: 24px;
        padding-bottom: 24px;
    }
}@media (min-width:48em) {
    .u-pv\-m {
        padding-top: 24px;
        padding-bottom: 24px;
    }
}@media (min-width:61.25em) {
    .u-pv\-l {
        padding-top: 24px;
        padding-bottom: 24px;
    }
}@media (min-width:20em) {
    .u-pv-tiny\-s {
        padding-top: 6px;
        padding-bottom: 6px;
    }
}@media (min-width:48em) {
    .u-pv-tiny\-m {
        padding-top: 6px;
        padding-bottom: 6px;
    }
}@media (min-width:61.25em) {
    .u-pv-tiny\-l {
        padding-top: 6px;
        padding-bottom: 6px;
    }
}@media (min-width:20em) {
    .u-pv-small\-s {
        padding-top: 12px;
        padding-bottom: 12px;
    }
}@media (min-width:48em) {
    .u-pv-small\-m {
        padding-top: 12px;
        padding-bottom: 12px;
    }
}@media (min-width:61.25em) {
    .u-pv-small\-l {
        padding-top: 12px;
        padding-bottom: 12px;
    }
}@media (min-width:20em) {
    .u-pv-large\-s {
        padding-top: 48px;
        padding-bottom: 48px;
    }
}@media (min-width:48em) {
    .u-pv-large\-m {
        padding-top: 48px;
        padding-bottom: 48px;
    }
}@media (min-width:61.25em) {
    .u-pv-large\-l {
        padding-top: 48px;
        padding-bottom: 48px;
    }
}@media (min-width:20em) {
    .u-pv-huge\-s {
        padding-top: 96px;
        padding-bottom: 96px;
    }
}@media (min-width:48em) {
    .u-pv-huge\-m {
        padding-top: 96px;
        padding-bottom: 96px;
    }
}@media (min-width:61.25em) {
    .u-pv-huge\-l {
        padding-top: 96px;
        padding-bottom: 96px;
    }
}@media (min-width:20em) {
    .u-pv-none\-s {
        padding-top: 0;
        padding-bottom: 0;
    }
}@media (min-width:48em) {
    .u-pv-none\-m {
        padding-top: 0;
        padding-bottom: 0;
    }
}@media (min-width:61.25em) {
    .u-pv-none\-l {
        padding-top: 0;
        padding-bottom: 0;
    }
}@media (min-width:20em) {
    .u-mt\-s {
        margin-top: 24px;
    }
}@media (min-width:48em) {
    .u-mt\-m {
        margin-top: 24px;
    }
}@media (min-width:61.25em) {
    .u-mt\-l {
        margin-top: 24px;
    }
}@media (min-width:20em) {
    .u-mt-tiny\-s {
        margin-top: 6px;
    }
}@media (min-width:48em) {
    .u-mt-tiny\-m {
        margin-top: 6px;
    }
}@media (min-width:61.25em) {
    .u-mt-tiny\-l {
        margin-top: 6px;
    }
}@media (min-width:20em) {
    .u-mt-small\-s {
        margin-top: 12px;
    }
}@media (min-width:48em) {
    .u-mt-small\-m {
        margin-top: 12px;
    }
}@media (min-width:61.25em) {
    .u-mt-small\-l {
        margin-top: 12px;
    }
}@media (min-width:20em) {
    .u-mt-large\-s {
        margin-top: 48px;
    }
}@media (min-width:48em) {
    .u-mt-large\-m {
        margin-top: 48px;
    }
}@media (min-width:61.25em) {
    .u-mt-large\-l {
        margin-top: 48px;
    }
}@media (min-width:20em) {
    .u-mt-huge\-s {
        margin-top: 96px;
    }
}@media (min-width:48em) {
    .u-mt-huge\-m {
        margin-top: 96px;
    }
}@media (min-width:61.25em) {
    .u-mt-huge\-l {
        margin-top: 96px;
    }
}@media (min-width:20em) {
    .u-mt-none\-s {
        margin-top: 0;
    }
}@media (min-width:48em) {
    .u-mt-none\-m {
        margin-top: 0;
    }
}@media (min-width:61.25em) {
    .u-mt-none\-l {
        margin-top: 0;
    }
}@media (min-width:20em) {
    .u-mr\-s {
        margin-right: 24px;
    }
}@media (min-width:48em) {
    .u-mr\-m {
        margin-right: 24px;
    }
}@media (min-width:61.25em) {
    .u-mr\-l {
        margin-right: 24px;
    }
}@media (min-width:20em) {
    .u-mr-tiny\-s {
        margin-right: 6px;
    }
}@media (min-width:48em) {
    .u-mr-tiny\-m {
        margin-right: 6px;
    }
}@media (min-width:61.25em) {
    .u-mr-tiny\-l {
        margin-right: 6px;
    }
}@media (min-width:20em) {
    .u-mr-small\-s {
        margin-right: 12px;
    }
}@media (min-width:48em) {
    .u-mr-small\-m {
        margin-right: 12px;
    }
}@media (min-width:61.25em) {
    .u-mr-small\-l {
        margin-right: 12px;
    }
}@media (min-width:20em) {
    .u-mr-large\-s {
        margin-right: 48px;
    }
}@media (min-width:48em) {
    .u-mr-large\-m {
        margin-right: 48px;
    }
}@media (min-width:61.25em) {
    .u-mr-large\-l {
        margin-right: 48px;
    }
}@media (min-width:20em) {
    .u-mr-huge\-s {
        margin-right: 96px;
    }
}@media (min-width:48em) {
    .u-mr-huge\-m {
        margin-right: 96px;
    }
}@media (min-width:61.25em) {
    .u-mr-huge\-l {
        margin-right: 96px;
    }
}@media (min-width:20em) {
    .u-mr-none\-s {
        margin-right: 0;
    }
}@media (min-width:48em) {
    .u-mr-none\-m {
        margin-right: 0;
    }
}@media (min-width:61.25em) {
    .u-mr-none\-l {
        margin-right: 0;
    }
}@media (min-width:20em) {
    .u-mb\-s {
        margin-bottom: 24px;
    }
}@media (min-width:48em) {
    .u-mb\-m {
        margin-bottom: 24px;
    }
}@media (min-width:61.25em) {
    .u-mb\-l {
        margin-bottom: 24px;
    }
}@media (min-width:20em) {
    .u-mb-tiny\-s {
        margin-bottom: 6px;
    }
}@media (min-width:48em) {
    .u-mb-tiny\-m {
        margin-bottom: 6px;
    }
}@media (min-width:61.25em) {
    .u-mb-tiny\-l {
        margin-bottom: 6px;
    }
}@media (min-width:20em) {
    .u-mb-small\-s {
        margin-bottom: 12px;
    }
}@media (min-width:48em) {
    .u-mb-small\-m {
        margin-bottom: 12px;
    }
}@media (min-width:61.25em) {
    .u-mb-small\-l {
        margin-bottom: 12px;
    }
}@media (min-width:20em) {
    .u-mb-large\-s {
        margin-bottom: 48px;
    }
}@media (min-width:48em) {
    .u-mb-large\-m {
        margin-bottom: 48px;
    }
}@media (min-width:61.25em) {
    .u-mb-large\-l {
        margin-bottom: 48px;
    }
}@media (min-width:20em) {
    .u-mb-huge\-s {
        margin-bottom: 96px;
    }
}@media (min-width:48em) {
    .u-mb-huge\-m {
        margin-bottom: 96px;
    }
}@media (min-width:61.25em) {
    .u-mb-huge\-l {
        margin-bottom: 96px;
    }
}@media (min-width:20em) {
    .u-mb-none\-s {
        margin-bottom: 0;
    }
}@media (min-width:48em) {
    .u-mb-none\-m {
        margin-bottom: 0;
    }
}@media (min-width:61.25em) {
    .u-mb-none\-l {
        margin-bottom: 0;
    }
}@media (min-width:20em) {
    .u-ml\-s {
        margin-left: 24px;
    }
}@media (min-width:48em) {
    .u-ml\-m {
        margin-left: 24px;
    }
}@media (min-width:61.25em) {
    .u-ml\-l {
        margin-left: 24px;
    }
}@media (min-width:20em) {
    .u-ml-tiny\-s {
        margin-left: 6px;
    }
}@media (min-width:48em) {
    .u-ml-tiny\-m {
        margin-left: 6px;
    }
}@media (min-width:61.25em) {
    .u-ml-tiny\-l {
        margin-left: 6px;
    }
}@media (min-width:20em) {
    .u-ml-small\-s {
        margin-left: 12px;
    }
}@media (min-width:48em) {
    .u-ml-small\-m {
        margin-left: 12px;
    }
}@media (min-width:61.25em) {
    .u-ml-small\-l {
        margin-left: 12px;
    }
}@media (min-width:20em) {
    .u-ml-large\-s {
        margin-left: 48px;
    }
}@media (min-width:48em) {
    .u-ml-large\-m {
        margin-left: 48px;
    }
}@media (min-width:61.25em) {
    .u-ml-large\-l {
        margin-left: 48px;
    }
}@media (min-width:20em) {
    .u-ml-huge\-s {
        margin-left: 96px;
    }
}@media (min-width:48em) {
    .u-ml-huge\-m {
        margin-left: 96px;
    }
}@media (min-width:61.25em) {
    .u-ml-huge\-l {
        margin-left: 96px;
    }
}@media (min-width:20em) {
    .u-ml-none\-s {
        margin-left: 0;
    }
}@media (min-width:48em) {
    .u-ml-none\-m {
        margin-left: 0;
    }
}@media (min-width:61.25em) {
    .u-ml-none\-l {
        margin-left: 0;
    }
}@media (min-width:20em) {
    .u-mh\-s {
        margin-left: 24px;
        margin-right: 24px;
    }
}@media (min-width:48em) {
    .u-mh\-m {
        margin-left: 24px;
        margin-right: 24px;
    }
}@media (min-width:61.25em) {
    .u-mh\-l {
        margin-left: 24px;
        margin-right: 24px;
    }
}@media (min-width:20em) {
    .u-mh-tiny\-s {
        margin-left: 6px;
        margin-right: 6px;
    }
}@media (min-width:48em) {
    .u-mh-tiny\-m {
        margin-left: 6px;
        margin-right: 6px;
    }
}@media (min-width:61.25em) {
    .u-mh-tiny\-l {
        margin-left: 6px;
        margin-right: 6px;
    }
}@media (min-width:20em) {
    .u-mh-small\-s {
        margin-left: 12px;
        margin-right: 12px;
    }
}@media (min-width:48em) {
    .u-mh-small\-m {
        margin-left: 12px;
        margin-right: 12px;
    }
}@media (min-width:61.25em) {
    .u-mh-small\-l {
        margin-left: 12px;
        margin-right: 12px;
    }
}@media (min-width:20em) {
    .u-mh-large\-s {
        margin-left: 48px;
        margin-right: 48px;
    }
}@media (min-width:48em) {
    .u-mh-large\-m {
        margin-left: 48px;
        margin-right: 48px;
    }
}@media (min-width:61.25em) {
    .u-mh-large\-l {
        margin-left: 48px;
        margin-right: 48px;
    }
}@media (min-width:20em) {
    .u-mh-huge\-s {
        margin-left: 96px;
        margin-right: 96px;
    }
}@media (min-width:48em) {
    .u-mh-huge\-m {
        margin-left: 96px;
        margin-right: 96px;
    }
}@media (min-width:61.25em) {
    .u-mh-huge\-l {
        margin-left: 96px;
        margin-right: 96px;
    }
}@media (min-width:20em) {
    .u-mh-none\-s {
        margin-left: 0;
        margin-right: 0;
    }
}@media (min-width:48em) {
    .u-mh-none\-m {
        margin-left: 0;
        margin-right: 0;
    }
}@media (min-width:61.25em) {
    .u-mh-none\-l {
        margin-left: 0;
        margin-right: 0;
    }
}@media (min-width:20em) {
    .u-mv\-s {
        margin-top: 24px;
        margin-bottom: 24px;
    }
}@media (min-width:48em) {
    .u-mv\-m {
        margin-top: 24px;
        margin-bottom: 24px;
    }
}@media (min-width:61.25em) {
    .u-mv\-l {
        margin-top: 24px;
        margin-bottom: 24px;
    }
}@media (min-width:20em) {
    .u-mv-tiny\-s {
        margin-top: 6px;
        margin-bottom: 6px;
    }
}@media (min-width:48em) {
    .u-mv-tiny\-m {
        margin-top: 6px;
        margin-bottom: 6px;
    }
}@media (min-width:61.25em) {
    .u-mv-tiny\-l {
        margin-top: 6px;
        margin-bottom: 6px;
    }
}@media (min-width:20em) {
    .u-mv-small\-s {
        margin-top: 12px;
        margin-bottom: 12px;
    }
}@media (min-width:48em) {
    .u-mv-small\-m {
        margin-top: 12px;
        margin-bottom: 12px;
    }
}@media (min-width:61.25em) {
    .u-mv-small\-l {
        margin-top: 12px;
        margin-bottom: 12px;
    }
}@media (min-width:20em) {
    .u-mv-large\-s {
        margin-top: 48px;
        margin-bottom: 48px;
    }
}@media (min-width:48em) {
    .u-mv-large\-m {
        margin-top: 48px;
        margin-bottom: 48px;
    }
}@media (min-width:61.25em) {
    .u-mv-large\-l {
        margin-top: 48px;
        margin-bottom: 48px;
    }
}@media (min-width:20em) {
    .u-mv-huge\-s {
        margin-top: 96px;
        margin-bottom: 96px;
    }
}@media (min-width:48em) {
    .u-mv-huge\-m {
        margin-top: 96px;
        margin-bottom: 96px;
    }
}@media (min-width:61.25em) {
    .u-mv-huge\-l {
        margin-top: 96px;
        margin-bottom: 96px;
    }
}@media (min-width:20em) {
    .u-mv-none\-s {
        margin-top: 0;
        margin-bottom: 0;
    }
}@media (min-width:48em) {
    .u-mv-none\-m {
        margin-top: 0;
        margin-bottom: 0;
    }
}@media (min-width:61.25em) {
    .u-mv-none\-l {
        margin-top: 0;
        margin-bottom: 0;
    }
}
