.page-recycling-danke {
    overflow-x: clip;

    .o-layout-0 img {
        width: 100%;
    }


    .u-1\/3-m {
        padding-right: 70px;
        padding-left: 70px !important;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media screen and (max-width: 767px) {
            padding-right: 0;
            padding-left: 0 !important;
        }
    }


    .u-1\/3-m img {
        width: auto;

        @media screen and (max-width: 767px) {
            width: 60%;
        }
    }

    .u-1\/3-m .image {
        display: flex;
        justify-content: center;
    }

    .u-h1 {
        font-size: 150px !important;
        margin-bottom: 0;
        line-height: 1 !important;
        @media screen and (max-width: 767px) {
            font-size: 80px !important;
        }
    }

    .u-h2 {
        color: #335d37 !important;
        margin-bottom: 12px !important;
        font-size: 24px !important;
        line-height: 1.2 !important;
        font-weight: bold;
    }

    .u-h3 {
        font-size: 100px !important;
        @media screen and (max-width: 767px) {
            font-size: 45px !important;
        }
    }


    .c-btn--download {
        width: 70%;
        height: 70px;

        &:hover {
            background-color: #e30613;
        }

        &:focus {
            background-color: #608163;
            color: #ffffff;
        }

        @media screen and (max-width: 767px) {
            width: 100%;
            height: 50px;
        }
    }


    .c-btn--primary {
        width: 50%;
        height: 70px;
        border-radius: 20px;

        @media screen and (max-width: 767px) {
            width: 100%;
            height: 50px;
        }
    }

    .c-btn--primary a {
        color: #ffffff;
    }

    .c-btn:hover {
        color: #ffffff;
    }

    img {
        margin-bottom: 24px;
    }

    .ntb__article {
        margin-bottom: 24px;
    }

    .c-content-slider {
        height: 400px;
    }

    .c-content-slider .slides {
        height: inherit;
    }

    .u-text-bigger {
        font-size: 26px;
        @media screen and (max-width: 767px) {
            font-size: 16px;
        }
    }

    .u-text-margin-right {
        margin-right: 48px;

        @media screen and (max-width: 767px) {
            margin-right: 0;
        }
    }

    .u-text-margin-left {
        margin-left: 48px;

        @media screen and (max-width: 767px) {
            margin-left: 0;
        }
    }
}
