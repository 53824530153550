.o-layout {
    display: flex;
    flex-wrap: wrap;
}

.o-layout-3 {
    margin-right: 20px;
    margin-left: 20px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;

    @media only screen and (min-width: 1024px) {
        margin-right: 59px;
        margin-left: 59px;
    }
}

.extra-small {
    margin-bottom: 6px !important;
}

.small {
    margin-bottom: 12px !important;
}

.medium {
    margin-bottom: 24px !important;
}

.large {
    margin-bottom: 48px !important;
}

.extra-large {
    margin-bottom: 96px !important;
}

