.page-main {
    padding-left  : 20px;
    padding-right : 20px;
    max-width     : 1366px;
    margin-top    : 0;

    @media only screen and (min-width : 1024px) {
        padding-left  : 60px;
        padding-right : 60px;
    }
}

.header.content {
    max-width : 1280px;
    padding   : 20px;
}

.columns .column.main {
    padding-bottom : 0 !important;
}

.block-search:before {
    @media only screen and (max-width : 767px) {
        width : calc(100% + 30px);
    }
}
