.page-sustainability {
    overflow-x: clip;
    .c-information-slider {

        position: relative;

        h3 {
            color: #ffffff;
            font-weight: bold;
            margin-top: 24px;
            font-size: 30px !important;
        }

        .c-information-slider__image {
            background-color: #f7f6f1;
            object-fit: contain;
            border-radius: 6px;
            margin-bottom: 24px;
        }

        .c-information-slider__slide {
            padding-right: 12px;
            padding-left: 12px;
            word-break: break-word;

        }

        .slides::-webkit-scrollbar {
            -webkit-appearance: none;
            display: none;
            background: transparent;
        }

        .glider-dots {
            margin: 48px 0;
        }

        .glider-dot {
            width: 40px;
            height: 4px;
            border-radius: 0;
        }

        .glider-dot.active {
            background: #f00000;
        }

        .c-information-slider__arrow {
            background-color: #ffffff;
            color: #646363;
            border: none;
            position: absolute;
            top: 125px;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;

            @media screen and (max-width: 1024px) {
                display: none;
            }
        }

        .c-information-slider__arrow.disabled {
            display: none;
        }

        .c-information-slider__arrow--next {
            right: 0;
        }

        .c-information-slider__arrow--prev {
            left: 0;
        }
    }


    h1 {
        font-size: 80px !important;
        color: #82A56B !important;
        margin-bottom: 0;
    }

    h2 {
        font-size: 70px !important;
    }

    h3 {
        font-size: 60px !important;
    }

    pre {
        font-family: 'Arial', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-size: 12px;
        background: none;
        border: none;
        color: #646363;
        white-space: break-spaces;
    }

    .neusta-icon-buttons {
        padding: 0 350px;
        margin-top: 48px;

        @media screen and (max-width: 1024px) {
            padding: 0 24px;
            margin-bottom: 48px;
        }

    }

    .neusta-icon-buttons-item {
        background-color: #82A56B !important;

        .title {
            color: #ffffff !important;
        }
    }


    .o-layout--small {
        justify-content: center;
    }

    .c-btn--tertiary {
        background: #82A56B !important;
        color: white !important;
        box-shadow: none;
        margin: auto;

        &:hover {
            border: none;
        }
    }

    .ce-image {
        display: flex;
        justify-content: center;

        .ce-gallery {
            width: 100%;

            img {
                width: 100%;
            }
        }
    }

    .u-3\/4-m {
        width: 60% !important;

        p {
            width: 80%;
        }

        .c-btn--tertiary {
            margin: unset;
        }
    }

    .u-1\/4-m {
        width: 40% !important;

        .ce-image {
            width: 100%;
            height: 100%;

            .ce-gallery {
                width: 100%;

                img {
                    border-radius: 6px;
                }
            }
        }
    }

    .u-1\/3-m {

        .image-embed-item {
            width: 100px;
            height: 100px;
            object-fit: contain;
        }
    }

    .u-1\/2-m {
        display: flex;
        justify-content: center;
    }


    .o-layout__item {
        .ce-gallery {
            width: 150px;
        }
    }

    .c-tear-background {
        padding-bottom: 72px;
        position: relative;
        background: url("/fileadmin/skeleton/TearOffBackground-top-bottom.png") no-repeat center top;
        background-size: cover;
        font-size: 18px;
        width: 100vw;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;

        img {
            width: 100vw !important;
            object-fit: cover;
        }

        h1 {
            color: #ffffff !important;
            padding: 48px 0 !important;
        }

        h3 {
            color: #ffffff !important;
        }

        h4 {
            color: #ffffff !important;
            font-size: 100px !important;
            position: absolute;
            top: 20%;
            left: 50%;
        }

        .c-btn {
            margin: auto;
            margin-bottom: 24px;
        }

        .u-text-center {
            padding: 0 350px;
        }

        .o-wrapper {
            border-bottom: none;
            padding-bottom: unset;
        }

    }

    .c-tear-background--bottom {
        background: url("/fileadmin/skeleton/TearOffEdgeBackground.png");
        background-size: cover;
        background-position: bottom;
        background-repeat: no-repeat;

        img {
            height: 500px;
        }
    }

    .c-tear-background--top-bottom {
        padding-top: 100px !important;
        padding-bottom: 100px !important;
        background-size: 100% 100%;
    }


    section.o-layout-0 {
        background-color: #E9E4DE;
        padding-top: 48px;
        padding-right: 24px;
        padding-left: 24px;

        p {
            padding: 0 24px;
            margin-bottom: 0;
        }

    }

    section.c-tear-background.o-layout-0 {
        background-color: transparent;
        padding-top: unset;
        padding-right: unset;
        padding-left: unset;

        p {
            margin-bottom: 24px;
        }
    }


    //MOBILE STYLES//

    @media screen and (max-width: 1024px) {

        h1 {
            line-height: 1 !important;
            margin-bottom: 24px;
        }

        h2 {
            line-height: 1 !important;
            font-size: 70px !important;
        }

        h3 {
            line-height: 1 !important;
            font-size: 50px !important;
        }

        .u-text-align-center {
            width: 100%;
        }

        .c-tear-background {

            img {
                height: 250px;
            }

            h1 {
                margin-bottom: 0;
            }

            h3 {
                margin-bottom: 24px;
            }

            h4 {
                top: 15%;
                font-size: 70px !important;
                width: 100%;
                left: unset;
                text-align: center;
            }

            .u-text-center {
                padding: 0 24px;
            }

            span {
                padding: 0 24px;
            }

        }

        .c-tear-background--top-bottom {
            background-size: cover;
        }

        .o-layout--small {
            flex-wrap: nowrap;
        }

        .o-layout__item {
            .ce-gallery {
                width: auto;

                img {
                    width: 100px;
                    height: 100px;
                    object-fit: contain;
                }
            }
        }

        .c-btn--tertiary {
            white-space: unset;
            padding: 12px 90px;
            width: 100%;
            justify-content: center;
        }

        .u-3\/4-m {
            width: 100% !important;

            h3 {
                display: none;
            }

            .c-btn {
                margin: auto;
            }

            p {
                width: 100%;
            }
        }

        .u-1\/4-m {
            width: 100% !important;

            .image-embed-item {
                width: 100% !important;
                height: 100% !important;
            }


            &:before {
                content: 'Nuk Little Friend';
                font-size: 50px;
                font-family: NorthernSoul, sans-serif;
                text-align: center;
                display: flex;
                justify-content: center;
                margin-bottom: 24px;
            }
        }

        .o-layout--small.large {
            flex-direction: column-reverse;
        }

        section.o-layout-0 {
            background-color: #E9E4DE;
            padding-top: 24px;
            padding-right: 12px;
            padding-left: 12px;

        }

        .c-information-slider {
            h3 {
                font-size: 23px !important;
            }
        }

    }
}
