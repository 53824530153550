$icon--Buerste: "\e914";
$icon--Service: "\e915";
$icon--Trinkflasche: "\e916";
$icon--Trinkflasche_Baby: "\e917";
$icon--Stillen_2: "\e918";
$icon--Rechnung: "\e919";
$icon--Rueckgabe: "\e91a";
$icon--Lieferung: "\e91b";
$icon--Stillen: "\e91c";
$icon--arrow-down: "\e901";
$icon--arrow-left: "\e902";
$icon--arrow-right: "\e903";
$icon--arrow-up: "\e904";
$icon--home: "\e905";
$icon--pencil: "\e906";
$icon--arrow-right-circle: "\e907";
$icon--deals: "\e908";
$icon--nuk-heart: "\e909";
$icon--hint: "\e90a";
$icon--eyedropper: "\e90b";
$icon--my-nuk: "\e90c";
$icon--new: "\e90d";
$icon--image: "\e90e";
$icon--pacifier: "\e90f";
$icon--camera: "\e910";
$icon--music: "\e911";
$icon--youtube: "\e912";
$icon--price-tag: "\e935";
$icon--cart: "\e93a";
$icon--lifebuoy: "\e941";
$icon--phone: "\e942";
$icon--envelop: "\e945";
$icon--location: "\e947";
$icon--floppy-disk: "\e962";
$icon--bubble: "\e96b";
$icon--bubbles: "\e970";
$icon--user: "\e971";
$icon--users: "\e972";
$icon--spinner: "\e97a";
$icon--search: "\e986";
$icon--zoom-in: "\e987";
$icon--zoom-out: "\e988";
$icon--enlarge: "\e989";
$icon--shrink: "\e98a";
$icon--key: "\e98d";
$icon--lock: "\e98f";
$icon--unlocked: "\e990";
$icon--wrench: "\e991";
$icon--equalizer: "\e993";
$icon--cog: "\e994";
$icon--cogs: "\e995";
$icon--compare: "\e99c";
$icon--bin: "\e9ad";
$icon--truck: "\e9b0";
$icon--menu: "\e9bd";
$icon--cloud-download: "\e9c2";
$icon--link: "\e9cb";
$icon--attachment: "\e9cd";
$icon--star-empty: "\e9d7";
$icon--star-half: "\e9d8";
$icon--star-full: "\e9d9";
$icon--heart: "\e9da";
$icon--warning: "\ea07";
$icon--notification: "\ea08";
$icon--plus: "\ea0a";
$icon--minus: "\ea0b";
$icon--info: "\ea0c";
$icon--cancel-circle: "\ea0d";
$icon--blocked: "\ea0e";
$icon--cross: "\ea0f";
$icon--checkmark: "\ea10";
$icon--circle-play: "\ea15";
$icon--play: "\ea1c";
$icon--pause: "\ea1d";
$icon--stop: "\ea1e";
$icon--volume-high: "\ea26";
$icon--volume-medium: "\ea27";
$icon--volume-low: "\ea28";
$icon--volume-min: "\ea29";
$icon--volume-mute: "\ea2a";
$icon--loop: "\ea2e";
$icon--arrow-desc: "\ea3a";
$icon--arrow-asc: "\ea3e";
$icon--circle-up: "\ea41";
$icon--circle-right: "\ea42";
$icon--circle-down: "\ea43";
$icon--circle-left: "\ea44";
$icon--checkbox-checked: "\ea52";
$icon--checkbox-unchecked: "\ea53";
$icon--radio-checked: "\ea54";
$icon--radio-unchecked: "\ea56";
$icon--filter: "\ea5b";
$icon--share: "\ea82";
$icon--google-plus: "\ea8b";
$icon--facebook: "\ea90";
$icon--instagram: "\ea92";
$icon--whatsapp: "\ea93";
$icon--twitter: "\ea96";
$icon--vimeo: "\eaa0";
$icon--paypal: "\ead8";
$icon--shop: "\ead9";
$icon--excklusive-light: "\e900";
$icon--excklusive: "\e913";

$icon-font                : 'iconfont';
$font-base                : 'Arial', sans-serif;
$font-headline            : 'NorthernSoul', sans-serif;

.font-professional {
    font-family : $font-headline;
}

/**
 * Font-Sizes
 *
 * The function is defined in '_tools.function_font-size.sass'
 *
 * Usage example: font-size(m)
 *
 * @type    sass-map
 */

$FONT-SIZES : (
    'xxxl': 80px,  // headline1
    'xxl': 70px,   // headline2
    'xxl-m': 40px, // headline1 & headline2 (mobile)
    'xl': 50px,    // headline3
    'xl-m': 32px,  // headline3 (mobile)
    'l': 24px,     // headline4
    'm': 18px,     // copy1
    's': 16px,     // copy2
    'xs': 14px,    // copy3
    'xxs': 12px,   // note
) !default;


/**
 * Line-Heights
 *
 * The function is defined in '_tools.function_line-height.sass'
 *
 * Usage example: line-height(m)
 *
 * @type    sass-map
 */

$LINE-HEIGHTS : (
    'xxxl': 85px,  // headline1
    'xxl': 80px,   // headline2
    'xxl-m': 45px, // headline1 & headline2 (mobile)
    'xl': 50px,    // headline3
    'xl-m': 40px,  // headline3 (mobile)
    'l': 32px,     // headline4
    'm': 28px,     // copy1
    's': 24px,     // copy2
    'xs': 16px,    // copy3
    'xxs': 18px,   // note
) !default;
