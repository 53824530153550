@font-face {
    font-family: 'NorthernSoul';
    src: url('../fonts/NorthernSoulCaps/northern-soul-caps.eot'); /* IE9 Compat Modes */
    src: url('../fonts/NorthernSoulCaps/northern-soul-caps.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/NorthernSoulCaps/northern-soul-caps.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/NorthernSoulCaps/northern-soul-caps.woff') format('woff'), /* Pretty Modern Browsers */
    url('../fonts/NorthernSoulCaps/northern-soul-caps.ttf')  format('truetype'); /* Safari, Android, iOS */
    font-display: swap;
}

@font-face {
    font-family: 'iconfont';
    src: url('../fonts/iconfont/iconfont.eot'); /* IE9 Compat Modes */
    src: url('../fonts/iconfont/iconfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/iconfont/iconfont.woff') format('woff'), /* Pretty Modern Browsers */
    url('../fonts/iconfont/iconfont.ttf')  format('truetype'); /* Safari, Android, iOS */
    font-display: swap;
}

@font-face {
    font-family: 'basic-sans';
    src: url('../fonts/BasicSans/BasicSans-Black.eot'); /* IE9 Compat Modes */
    src: url('../fonts/BasicSans/BasicSans-Black.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/BasicSans/BasicSans-Black.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/BasicSans/BasicSans-Black.woff') format('woff'); /* Pretty Modern Browsers */
    font-display: swap;
}
@font-face {
    font-family: 'basic-sans-light';
    src: url('../fonts/BasicSans/BasicSans-Light.eot'); /* IE9 Compat Modes */
    src: url('../fonts/BasicSans/BasicSans-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/BasicSans/BasicSans-Light.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/BasicSans/BasicSans-Light.woff') format('woff'); /* Pretty Modern Browsers */
    font-display: swap;
}
